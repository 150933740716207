export class Constants {
  public static NewPopupDate = '11/05/2019';
  public static SpringTrainingStartDt = '2/14/2024';
  public static OpeningDay = '3/30/2023';
  public static MapKey =
    'EsIuyuVRbdiJre9EbYC5~RHS1Lfzht-Y2TeazoiwoQg~AqPJSUO10kf5USv-cslIDNmAEqxvXaWASO-bVEHLYVVVVvkZOuHEp2UHWW_Bv5da';
  public static ResponsiveWidthTrigger = 1194;
  public static GeneralListWidth = 319;

  public static StateIL = 15;
  public static HQLatitude = 41.854027;
  public static HQLongitude = -87.9219465;

  public static CalendarHR = 3;
  public static CalendarBirthdays = 4;
  public static CalendarAnniversary = 8;
  public static CalendarInvClose = 9;

  // screens using dictionary columns
  public static ScreenLandSchedule = 1;
  public static ScreenCIPSchedule = 2;
  public static ScreenPropertySchedule = 3;
  public static ScreenPropertyExport = 4;
  public static ScreenUnitExport = 5;
  public static ScreenInvestments = 6;

  // acq
  public static AcqStatusUnderReview = 1;
  public static AcqStatusSignedLOI = 2;
  public static AcqStatusUnderContract = 3;
  public static AcqStatusClosed = 4;

  // disposition status
  public static DispStatusMarketed = 1;
  public static DispStatusSignedLOI = 2;
  public static DispStatusUnderContract = 3;
  public static DispStatusClosed = 4;

  // disposition type
  public static DispTypeInsustrial = 1;
  public static DispTypeTransload = 2;
  public static DispTypeLand = 3;

  // prop
  public static PropCategoryLand = 5;
  public static PropCategoryCIP = 4;
  public static PropCategoryFinancial = 3;
  public static PropCategoryProperty = 1;

  // lookup values
  public static Lkp_Inv_Status_UnderReview = 1;
  public static Lkp_Inv_PipelineGroup_Property = 1;
  public static Lkp_Region_Central_North = 1025;
  public static Lkp_Region_East = 1010;
  public static Lkp_Region_West = 1009;
  public static Lkp_Dev_Type_BTSForLease = 1;
  public static Lkp_Dev_Type_BTSForSale = 2;
  public static Lkp_Dev_Type_Spec = 3;
  public static Lkp_Dev_Type_Fee = 4;
  public static Lkp_Dev_Type_Other = 5;
  public static Lkp_Dev_Type_LandSale = 6;
  public static Lkp_Prop_Type_Bulk_Dist = 2;

  // system colors
  public static ColorBlue = '#21376c';
  public static ColorOrange = '#EA9721';
  public static ColorBrightBlue = '#0F99D6';
  public static ColorGreen = '#006D15';
  public static ColorRed = '#b22222';

  // ***** PERMISSIONS *****
  // perm functions
  public static PermPropertyEditProperty = 463;
  public static PermPropertyPromote = 406;
  public static PermPropertyManageUnits = 479;
  public static PermPropertyView = 794;
  public static PermPropertyEditIDs = 453;
  public static PermPropertyEditEntity = 825;
  public static PermPropertyEditSubEntity = 826;
  public static PermPropertyEditManagingFirm = 827;
  public static PermDCFEdit = 746;
  public static PermDCFView = 747;
  public static PermLeaseView = 790;
  public static PermLeaseEdit = 791;
  public static PermLeaseBarneyView = 829;
  public static PermLeaseBarneyEdit = 830;
  public static PermLeaseBarneyApprove = 831;
  public static PermLeaseBarneyBudgetAdmin = 833;
  public static PermLeaseBarneyDowntimeAnalysis = 938;
  public static PermConstructionView = 643;
  public static PermClosingBookView = 856;
  public static PermClosingBookEdit = 857;
  public static PermCantEditFilters = 867;
  public static PermCanViewTopTenants = 868;
  public static PermTimesheetGod = 678;
  public static PermTenantCompanyView = 885;
  public static PermTenantCompanyEdit = 886;
  public static PermTenantParentEdit = 887;
  public static PermEsgEfficiencyView = 890;
  public static PermEsgEfficiencyEdit = 891;
  // system rename
  public static PermConstructionEfficiencyProjectView = 890;
  public static PermConstructionEfficiencyProjectEdit = 891;


  public static PermEsgQtrChangeEdit = 894;
  public static PermBudgetLeasingView = 897;
  public static PermBudgetLeasingEdit = 898;
  public static PermBudgetLeasingApprove = 899;

  public static PermGresbEfficiencyMeasuresView = 906;
  public static PermGresbEfficiencyMeasuresEdit = 907;
  public static PermGresbEfficiencyMeasuresFYChange = 908;

  public static PermEnvApprovalsView = 914;
  public static PermEnvApprovalsEdit = 915;
  public static PermEnvApprovalsFYChange = 916;
  public static PermEsgDataMgmtView = 920;
  public static PermEsgDataMgmtEdit = 921;
  public static PermEsgDataMgmtQtrChange = 922;
  public static PermCorpEntitiesView = 876;
  public static PermCorpEntitiesEdit = 877;
  public static PermUserIsEmployeeStatusEdit = 929;
  public static PermCorpEntitiesBizLicenseEdit = 933;
  public static PermCorpEntitiesSalesTaxEdit = 934;

  public static PermHirrAnalysisView = 940;
  public static PermHirrAnalysisEdit = 941;
  public static PermHirrAnalysisManagementFeaturesEdit = 943;

  public static PermAppraisalsManagementFeatures = 956;

  public static PermEsgPipelineEdit = 961;

  // perm levels
  public static PermLevel_EXECUTE = 1;
  public static PermLevel_NO_ACCESS = 2;
  public static PermLevel_ENTER = 3;
  public static PermLevel_EDIT = 4;
  public static PermLevel_APPROVE = 5;
  public static PermLevel_DELETE = 6;
  public static PermLevel_NO_EDIT = 7;
  public static PermLevel_NO_DELETE = 8;
  public static PermLevel_CHANGE_DB = 9;
  public static PermLevel_PROMOTE = 10;
  public static PermLevel_NEW = 11;
  public static PermLevel_KILL = 12;
  public static PermLevel_RUN = 13;
  public static PermLevel_VIEW = 14;
  public static PermLevel_SUBMIT = 15;
  public static PermLevel_REJECT = 16;
  public static PermLevel_EMAIL = 17;
  public static PermLevel_INHERIT = 18;
  public static PermLevel_FULL_ACCESS = 19;

  public static RptKey_AppraisalMasterReport = 819;
  public static RptKey_PropertyProfile = 13;
  public static RptKey_PropertyList = 399;
  public static RptKey_PropertyAge = 172;
  public static RptKey_PropertyVacancy = 391;
  public static RptKey_PropertyOccupancyByQtr = 810;
  public static RptKey_PropertyVacancyAndExpiring = 720;
  public static RptKey_LeaseAbstractBrochure = 401;
  public static RptKey_AbstractResponsibility = 750;
  public static RptKey_LeaseAbstractByProperty = 602;
  public static RptKey_LeaseAudit = 691;
  public static RptKey_TenantRoster = 7;
  public static RptKey_LeaseExpirationSchedule = 6;

  public static RptPath_AppraisalMasterReport = 'CUBS/Appraisal_Master_Report';
  public static RptPath_AppraisalInputAssumptionsReport = 'CUBS/Appraisal_Input_Assumptions_For_Period';
  public static RptPath_AppraisalVarianceReport = 'CUBS/Appraisal_Period_Variance';
  public static RptPath_AppraisalMarketAssumptionsReport = 'CUBS/Appraisal_Market_Assumptions_For_Period';
  public static RptPath_AppraisalCapExReport = 'CUBS/Appraisal_CapEx_For_Period';
  public static RptPath_AppraisalMarketAnalysisReport = 'CUBS/Appraisal_Market_Rent_Analysis';
  public static RptPath_ConstructionBudgetSummaryReport = 'CUBS/YARDI_Construction_Budget_Summary_New';
  public static RptPath_PropertyProfile = 'CUBS/Property_Profile';
  public static RptPath_PropertyList = 'CUBS/PropertyList';
  public static RptPath_PropertyAge = 'CUBS/Property_Age';
  public static RptPath_PropertyVacancy = 'CUBS/PropertyVacancy';
  public static RptPath_PropertyOccupancyByQtr = 'CUBS/PropertyOccupancyByQtr';
  public static RptPath_PropertyVacancyAndExpiring =
    'CUBS/VacancyAndExpirationByProperty';
  public static RptPath_LeaseAbstractBrochure = 'CUBS/Lease_Abstract_Brochure';
  public static RptPath_AbstractResponsibility =
    'CUBS/TenantAbstractResponsibility';
  public static RptPath_LeaseAbstractByProperty =
    'CUBS/Lease_Abstract_Brochure_By_Property';
  public static RptPath_LeaseAudit = 'CUBS/AuditNewAmendedLeases';
  public static RptPath_TenantRoster = 'CUBS/Tenant_Roster';
  public static RptPath_LeaseExpirationSchedule = 'CUBS/Lease_Expiration_Schedule';
  public static RptPath_LeaseBarneyMasterReport = 'CUBS/LeaseBarneyMasterReport2';
  public static RptPath_AnnualExpiringLeaseSchedule = 'CUBS/Expiring_Lease_Schedule';
  public static RptPath_ExpiringLeaseScheduleAll = 'CUBS/Expiring_Lease_Schedule_All';
  public static RptPath_ExpiringLeaseScheduleSummary = 'CUBS/Expiring_Lease_Schedule_Summary';
  public static RptPath_ConstructionEfficiencyProjectSummary = 'CUBS/ConstructionEfficiencyProjectsSummary';
  public static RptPath_ConstructionEfficiencyProjectDetail = 'CUBS/ConstructionEfficiencyProjectsDetail';
  public static RptPath_GresbEfficiencyMeasures = 'CUBS/GresbEfficiencyMeasures';
  public static RptPath_HirrAnalysisProperties = 'CUBS/HirrAnalysisProperties';
  public static RptPath_HirrAnalysisPropertiesDetail = 'CUBS/HirrAnalysisPropertiesDetail';
  public static RptPath_CalpersAssetRanking = 'CUBS/assetRanking2Calpers';
  public static RptPath_CalpersAssetRankingPoints = 'CUBS/assetRanking2Calpers-Points';
  public static InvOwnershipPct = 0.9959;
  public static CalpersOwnershipPct = 0.9959;

  // property types
  public static PropertyTypeOffice = 1;
  public static PropertyTypeIndustrial = 2;
  public static PropertyTypeResidential = 3;
  public static PropertyTypeRetail = 4;
  public static PropertyTypeLand = 5;
  public static PropertyTypeOther = 6;
  public static PropertyTypeNotLeasable = 7;
  public static PropertyTypeCorporate = 8;
  public static PropertyTypeAssociation = 9;
  public static PropertyTypeIntermodal = 10;
  public static PropertyTypeContainer = 11;
  public static PropertyTypeLoan = 12;
  public static PropertyTypeTruckTerminal = 13;
  public static PropertyTypeLeasedLand = 19;
  public static PropertyTypeStepUp = 20;

  // property categories
  public static PropertyCategoryProperty = 1;
  public static PropertyCategoryFinancial = 3;
  public static PropertyCategoryCIP = 4;
  public static PropertyCategoryLand = 5;
  public static PropertyCategoryAssociation = 6;
  public static PropertyCategoryCorporate = 7;
  public static PropertyCategoryManagedNotOwned = 9;
  public static PropertyCategoryAllocatedLand = 10;
  public static PropertyCategoryConsolidation = 11;

  // class
  public static PropertyClassInvOperating = 1006;

  public static PropertyClassDevPipeline = 3002;
  public static PropertyClassDevOperating = 3005;

  // prop group
  public static PropertyGroupDev = 1004;

  // property stuff
  public static PropertyOwningEntityDescCNT = 'CNT';

  // vendor certificate category
  public static CertificateNNSA = 1;
  public static CertificateBannister = 3;

  // bad data status
  public static BadDataStatusOpen = 1;
  public static BadDataStatusResolved = 2;

  // bad data system
  public static BadDataSystemGeneral = 1;
  public static BadDataSystemProperty = 2;
  public static BadDataSystemLease = 3;

  // bad data MIS PC keys
  public static PCKeyKevin = 7369;
  public static PCKeyRick = 7454;

  // FiscalYear
  public static FiscalYr = 2025;

  // workflow item statusx
  public static WorkflowItemStatusNotReviewed = 1;
  public static WorkflowItemStatusSubmitted = 2;
  public static WorkflowItemStatusApproved = 3;
  public static WorkflowItemStatusRejected = 4;
  public static WorkflowItemStatusDeleted = 5;

  // workflow item types
  public static WorkflowItemTypeSignOff = 1;
  public static WorkflowItemTypeApproval = 2;

  // lease barney status
  public static LeaseBarneyNotReviewed = 1;
  public static LeaseBarneySubmitted = 2;
  public static LeaseBarneyRejected = 3;
  public static LeaseBarneyDeleted = 4;
  public static LeaseBarneyApproved = 5;

  public static WorkflowApprovalLevelLeaseBarneyNone = 1;
  public static WorkflowApprovalLevelLeaseBarneyCOO = 2;
  public static WorkflowApprovalLevelLeaseBarneyEC = 3;
  public static WorkflowApprovalLevelClosingBookBlueBook = 4;
  public static WorkflowApprovalLevelDataBucketFinance = 5;

  public static AppraisalStatusNotReviewed = 1;
  public static AppraisalStatusInternallyApproved = 2;
  public static AppraisalStatusFinalized = 3;
  public static AppraisalStatusRejected = 4;
  public static AppraisalStatusFlaggedForProblem = 5;
  public static AppraisalStatusUnderReview = 6;

  // application keys
  public static ApplicationLeaseBarney = 121;
  public static ApplicationWorkflow = 122;
  public static ApplicationClosingBook = 123;
  public static ApplicationDataBucket = 125;
  public static ApplicationCorporateLog = 126;
  public static ApplicationTenantCompany = 128;
  public static ApplicationEsgEnergyEfficiency = 129;
  public static ApplicationEnvironmentalApprovals = 132;

  // closing book types
  public static ClosingBookInvestmentInternal = 1;
  public static ClosingBookDispoInternal = 2;

  public static ClosingBookInvestmentInternalName = 'Investment Closing Book (Internal)';
  public static ClosingBookDispoInternalName = 'Disposition Closing Book (Internal)';

  // advance land/property
  public static MoveToCIP = 1;
  public static MoveToUnderDevelopment = 2;
  public static MoveToProperty = 3;

  // Bonus Status (Forecast/Actual)
  public static BonusStatus = 'Forecast';

  // ESG Pipeline stages
  public static EsgStageProspect = 1;
  public static EsgStageAnalyzePropose = 2;
  public static EsgStageBooked = 3;
  public static EsgStageUnderConstruction = 4;
  public static EsgStageStabilized = 5;

  // Lease Class keys
  public static LeaseClassPrePipeline = 4000;
  public static LeaseClassOfferProposal = 4001;
  public static LeaseClassLOI = 4002;
  public static LeaseClassUnderContract = 4003;
  public static LeaseClassClosedTransferring = 4004;
  public static LeaseClassOperating = 4005;
  public static LeaseClassDeadDeal = 4006;
  public static LeaseClassTerminated = 4007;
}
