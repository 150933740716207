import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { NgIf, NgFor } from '@angular/common';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { User, SportsTeam } from './../user/user';
import { LoggerService } from '../core/logger.service';
import { fadeInAnimation } from '../shared/animations';
import { PortalService } from '../core/portal.service';
import { MenuService } from '../core/menu.service';
import { UserService } from './../core/user.service';
import { SystemData, CalendarObject, CalendarEvent } from './portalData';
import { Constants } from '../core/constants';
import { Bracket } from 'src/app/misc/march-madness/bracket/bracket';
import { CalendarEvent as CoreCalendarEvent } from 'src/app/core/calendarEvent';

import { faComments } from '@fortawesome/pro-light-svg-icons/faComments';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faUtensils } from '@fortawesome/pro-light-svg-icons/faUtensils';
import {
  faCloudSleet, faFluxCapacitor, faCaretDown, faCalendar, faBirthdayCake, faPresentationScreen, faSquareDollar, faShovel,
  faPartyHorn, faClockDesk, faBasketball, faBasketballHoop, faFile, faFileWord, faBrowser, faLocationDot, faClockOneThirty,
  faBasketballBall
} from '@fortawesome/pro-light-svg-icons';

import * as moment from 'moment';
import { QuarterlyMetric } from './quarterlyMetric';

import { CalendarOptions, EventInput } from '@fullcalendar/core';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import { BracketService } from '../misc/march-madness/bracket.service';
import { QtrSystemDataPipe } from './qtrSystemData.pipe';
import { WidgetGoalsComponent } from './widget-goals/widget-goals.component';
import { WidgetLineGraphColoredComponent } from './widget-line-graph-colored/widget-line-graph-colored.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { EventTooltipDirective } from './event-tooltip.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
  selector: 'cub-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [fadeInAnimation],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    MatSidenavModule,
    FontAwesomeModule,
    NgFor,
    EventTooltipDirective,
    MatButtonModule,
    MatMenuModule,
    WidgetLineGraphColoredComponent,
    FullCalendarModule,
    WidgetGoalsComponent,
    QtrSystemDataPipe,
  ],
})
export class DashboardComponent implements OnInit {

  @ViewChild('calendarWeek1') calendarComponent1: FullCalendarComponent;
  @ViewChild('calendarWeek2') calendarComponent2: FullCalendarComponent;

  showOldCUB = false;
  me: User;
  showNewPoint = false;
  showPointTip = false;

  faBasketballBall = faBasketballBall;
  faComments = faComments;
  faPlus = faPlus;
  faUtensils = faUtensils;
  faCloudSleet = faCloudSleet;
  faFluxCapacitor = faFluxCapacitor;
  faCaretDown = faCaretDown;
  faCalendar = faCalendar;
  faBirthdayCake = faBirthdayCake;
  faPresentationScreen = faPresentationScreen;
  faSquareDollar = faSquareDollar;
  faPartyHorn = faPartyHorn;
  faShovel = faShovel;
  faBasketball = faBasketball;
  faBasketballHoop = faBasketballHoop;
  faFile = faFile;
  faFileWord = faFileWord;
  faBrowser = faBrowser;
  faLocationDot = faLocationDot;
  faClockOneThirty = faClockOneThirty;

  calendarOptions: CalendarOptions;
  calendarVisible = true;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarWeekends = false;
  calendarEvents: EventInput[] = [
    { title: 'Event Now', start: new Date() }
  ];
  eventsFromServer: CoreCalendarEvent[] = [];

  systemData: SystemData;
  calendarObjects: CalendarObject[] = [];
  quarterlySystemData: QuarterlyMetric[] = [];
  propChartLabels = [];
  propChartData = [{ data: [], label: '' }];
  leaseChartLabels = [];
  leaseChartData = [{ data: [], label: '' }];
  sharePriceLatest: string;
  sharePriceChartLabels = [];
  sharePriceChartData = [{ data: [], label: '' }];
  leverageLatest: string;
  leverageChartLabels = [];
  leverageChartData = [{ data: [], label: '' }];
  goalData = [];
  appraisalChartLabels = [];
  appraisalChartData = [{ data: [], label: '' }];
  sfVsAcresChartLabels = [];
  sfVsAcresChartData = [{ data: [], label: '' }];
  revenueChartLabels = [];
  revenueChartData = [];
  sfMultiplier = 4000;
  pieChartColors: string[];
  dashboardSections = ['Asset Management', 'Corporate', 'Investments'];
  showPoint = true;
  pointSideNavMode = 'side';
  screenWidth: number;
  appraisalVal1: string;
  appraisalVal2: string;
  sfAcreVal1: string;
  sfAcreVal2: string;
  currentSf = 0;
  sfChartLabels = [];
  sfChartData = [{ data: [], label: '' }];
  occChartLabels = [];
  occChartData = [{ data: [], label: '' }];
  currentOcc = 0;
  acreChartAmount: string;
  acreChartData = [{ data: [], label: '' }];
  acreChartLabels = [];
  navAmount: string;
  navChartData = [{ data: [], label: '' }];
  navChartLabels = [];
  selQuarterIndex = 0;
  selQuarterName: string;
  outsideUser = false;

  eventsToday: CalendarEvent[] = [];
  eventsTomorrow: CalendarEvent[] = [];
  eventsRestOfWeek: CalendarEvent[] = [];
  eventsNextWeek: CalendarEvent[] = [];
  eventsLater: CalendarEvent[] = [];
  selectedEvent: CalendarEvent;
  selectedPersonalEvent: any;
  myEvents: any[] = [];
  myEventsLoading = true;
  myFiles: any[] = [];
  officeRecents: any[] = [];
  myDetails: any;
  nextPasswordChange: Date;

  brackets: Bracket[] = [];
  masterBracket: Bracket;
  allBrackets: Bracket[] = [];
  bracketYear = moment().year().toString();
  isMarchMadnessTime = false;
  loadingBrackets = true;

  constructor(
    private dp: DecimalPipe,
    private loggerService: LoggerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    public dialog: MatDialog,
    private menuService: MenuService,
    private portalService: PortalService,
    private userService: UserService,
    private bracketService: BracketService
  ) {
    this.nextPasswordChange = moment().add(180, 'days').toDate();  // just temp value before we get real value from server
    this.screenWidth = window.innerWidth;
    if (this.screenWidth <= Constants.ResponsiveWidthTrigger) {
      this.pointSideNavMode = 'over';
    }
    this.me = new User();
    this.pieChartColors = [Constants.ColorBlue, Constants.ColorGreen, Constants.ColorOrange, Constants.ColorBrightBlue, Constants.ColorRed];

    this.userService.userData$.subscribe(() => {
      this.me = JSON.parse(localStorage.getItem('user')) as User;
      this.setupSections();
    });
    this.systemData = JSON.parse(localStorage.getItem('systemData')) as SystemData;
    this.filterCalendarObjects();
    this.outsideUser = this.userService.hasPermissionKey(Constants.PermCantEditFilters, Constants.PermLevel_NO_ACCESS);

    if (this.isMarchMadnessTime) {
      this.getBrackets();
    }

    setTimeout(() => {
      let calendar1Api = this.calendarComponent1.getApi();
      calendar1Api.gotoDate(moment().startOf('week').toDate());
      let calendar2Api = this.calendarComponent2.getApi();
      calendar2Api.gotoDate(moment().startOf('week').add(7, 'days').toDate());
      // this.userService.refreshUserFromServer();  //cac test to see how much this slows down home page load...I don't notice anything...
      this.updateCalendar();
      this.getRecentFiles();
      this.getUserCalendarEvents();
      this.getRecentOfficeItems();
      this.getMyDetails();
    }, 1000);

    setInterval(() => {
      this.getUserCalendarEvents();
    }, 1000 * 60 * 1);  // 5 minutes
  }

  getMyDetails() {
    this.userService.getMyDetails().subscribe(d => {
      this.myDetails = d;
      this.doWorkOnUser();
    });
  }

  openRecentItem(recentItem: any) {
    if (recentItem.resourceReference.webUrl) {
      window.open(recentItem.resourceReference.webUrl, '_blank');
    } else {
      window.open(recentItem.resourceVisualization.containerWebUrl, '_blank');
    }
  }

  doWorkOnUser() {
    this.nextPasswordChange = moment(this.myDetails.lastPasswordChangeDateTime).add(180, 'days').toDate();
  }

  getRecentOfficeItems() {
    this.userService.getRecentOfficeItems().subscribe(d => {
      this.officeRecents = d.value;
    });
  }

  getRecentFiles() {
    this.userService.getRecentFiles().subscribe(d => {
      this.myFiles = d.value;
    });
  }

  getUserCalendarEvents() {
    this.myEventsLoading = true;
    this.userService.getUserEvents().subscribe(d => {
      this.myEvents = d.value;
      this.myEventsLoading = false;
    });
  }

  getRecentItemIcon(recentItem: any) {
    let fa = faCalendar;
    switch (recentItem.resourceVisualization.type) {
      case 'Word':
        fa = faFileWord;
        break;
      case 'Web':
      case 'spsite':
        fa = faBrowser;
        break;
      default:
        fa = faFile;
        break;
    }
    return fa;
  }

  getMeetingTimeDesc(startDtString: string, endDtString: string): string {
    let timeDesc = '';
    let localizedStartDt = this.convertUtcToLocal(startDtString);
    let localizedEndDt = this.convertUtcToLocal(endDtString);
    let today = moment(localizedStartDt).dayOfYear() === moment().dayOfYear();
    let tomorrow = moment(localizedStartDt).dayOfYear() === moment().add(1, 'd').dayOfYear();
    let sunday = moment().startOf('week');
    let minutesAway = moment(localizedStartDt).diff(moment(), 'minutes');

    if (minutesAway === 0 || moment() > moment(localizedStartDt) && moment() < moment(localizedEndDt)) {
      timeDesc = 'in progress';
    } else if (minutesAway < 60) {
      timeDesc = 'in ' + minutesAway + ' minute' + (minutesAway > 1 ? 's' : '');
    } else if (today) {
      timeDesc = 'Today, ' + moment(localizedStartDt).format('h:mm a');
    } else if (tomorrow) {
      timeDesc = 'Tomorrow, ' + moment(localizedStartDt).format('h:mm a');
    } else if (moment(localizedStartDt).dayOfYear() < sunday.dayOfYear()) {  // this week
      timeDesc = moment(localizedStartDt).format('dddd, h:mm a');
    } else {
      timeDesc = moment(localizedStartDt).format('MMM D, h:mm a');
    }
    timeDesc;
    return timeDesc;
  }

  convertUtcToLocal(utcDate: string): string {
    const localDateObj = moment(utcDate).format('MMM D YYYY, h:mm a') + ' UTC';
    return moment(new Date(localDateObj).toString()).format('MMM D YYYY, h:mm a');
  }

  showEvent(event: any): boolean {
    let showEvent = true;
    if (moment(this.convertUtcToLocal(event.end.dateTime)) < moment()) {
      showEvent = false;
    }
    return showEvent;
  }

  openInOutlook(event: any): void {
    //const outlookLink = this.createOutlookLink(event);
    window.open(event.webLink);
    this.loggerService.logPageVisit('open outlook event', this.me.accountName);
  }

  createOutlookLink(event: any): string {
    // this was an attempt to create a link that would open the event in outlook - not working
    const utcStart = moment.utc(event.start.dateTime);
    const localStart = moment(utcStart).local();
    const utcEnd = moment.utc(event.end.dateTime);
    const localEnd = moment(utcEnd).local();

    const start = localStart.format('YYYY-MM-DDTHH:mm:ss');
    const end = localEnd.format('YYYY-MM-DDTHH:mm:ss');
    const webLink = event.webLink;
    //const url = `https://outlook.office.com/calendar/view/${event.id}/`;
    const outlookLink = encodeURI(`outlook://eventid=${event.id}&sta'rtdt=${start}&enddt=${end}&subject=${event.subject}&location=${event.location.displayName}&body=${event.bodyPreview}&locationURL=${webLink}`);
    //let anotherUrl = `outlook://calendar/item/${event.id}`;

    return outlookLink; // + '&ispopout=0'; //.replace('https://outlook.office365.com/owa/?itemid', 'outlook://eventid');
  }

  updateCalendar() {
    this.portalService.getCalendarEvents().subscribe(d => {
      this.eventsFromServer = d;
      this.eventsFromServer.forEach(e => {
        if ((e.calendarKey === Constants.CalendarBirthdays || e.calendarKey === Constants.CalendarAnniversary) && moment(e.calendarStartDt).isoWeekday() > 5) {
          e.calendarStartDt = moment(e.calendarStartDt).isoWeekday(5).toDate();
        } else {
          e.calendarStartDt = moment(e.calendarStartDt).toDate();
        }
        if (moment(e.calendarEndDt).hour() === 23 && moment(e.calendarEndDt).minute() === 59) {
          e.calendarEndDt = moment(e.calendarEndDt).add(1, 'minute').toDate();  // corrects issue with full calendar not showing last day of multi-day event
        } else {
          e.calendarEndDt = moment(e.calendarEndDt).toDate();
        }
        e.calendarStartDt = moment(e.calendarStartDt).hour(0).minute(0).second(0).millisecond(0).toDate();
        e.calendarEndDt = moment(e.calendarEndDt).hour(0).minute(0).second(0).millisecond(0).toDate();
      });
      this.calendarEvents = this.eventsFromServer.map(c => ({
        id: c.id.toString(),
        title: c.title,
        start: c.calendarStartDt,
        end: c.calendarEndDt,
        allDay: true,
        url: c.url,
        extendedProps: {
          isBirthday: (c.calendarKey === Constants.CalendarBirthdays),
          isAnniversary: (c.calendarKey === Constants.CalendarAnniversary),
          isInvClose: (c.calendarKey === Constants.CalendarInvClose),
          IsHR: (c.calendarKey === Constants.CalendarHR)
        }
      }));
      this.calendarEvents.forEach(c => {
        c.backgroundColor = this.getBgColor(c);
        c.borderColor = this.getBorderColor(c);
        c.textColor = this.getColor(c);
      });

      this.calendarEvents = this.calendarEvents.filter(e => e.title?.trim().length > 0);

      [
        this.calendarComponent1,
        this.calendarComponent2
      ].forEach(c => {
        c.getApi().removeAllEvents();
        c.getApi().addEventSource(this.calendarEvents);
      });
    });
  }

  getColor(evt: any) {
    if (evt.extendedProps.isBirthday === true) {
      return '#333';
    } else if (evt.extendedProps.isAnniversary === true) {
      return '#333';
    } else if (evt.extendedProps.isInvClose === true) {
      return '#fff';
    } else if (evt.extendedProps.IsHR === true) {
      return '#fff';
    } else {
      return '#fff';
    }
  }

  getBgColor(evt: any) {
    if (evt.extendedProps.isBirthday === true) {
      return '#fafafa';
    } else if (evt.extendedProps.isAnniversary === true) {
      return '#fafafa';
    } else if (evt.extendedProps.isInvClose === true) {
      return '#21376c';
    } else if (evt.extendedProps.IsHR === true) {
      return '#0F99D6';
    } else {
      return '#06a77c';
    }
  }

  getBorderColor(evt: any) {
    if (evt.extendedProps.isBirthday === true) {
      return '#fafafa';
    } else if (evt.extendedProps.isAnniversary === true) {
      return '#fafafa';
    } else if (evt.extendedProps.isInvClose === true) {
      return '#21376c';
    } else if (evt.extendedProps.IsHR === true) {
      return '#0F99D6';
    } else {
      return '#06a77c';
    }
  }

  filterCalendarObjects() {
    let calEvents = this.systemData?.calendarEvents.filter(c => moment(c.startDt).isBefore(moment().startOf('isoWeek').add(13, 'days')));
    this.calendarObjects = this.systemData?.calendarObjects.filter(c => calEvents.filter(c2 => c2.key === c.eventKey).length > 0);

    this.eventsToday = this.systemData?.calendarEvents.filter(c => moment(c.calendarStartDt).isBefore(moment()) && moment(c.calendarEndDt).isAfter(moment()));
    this.eventsTomorrow = this.systemData?.calendarEvents.filter(c => moment(c.calendarStartDt).isBefore(moment().add(1, 'day')) && moment(c.calendarEndDt).isAfter(moment().add(1, 'day')));

    let dt = moment();
    dt.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    // only showing rest of week if it's earlier than Friday
    if (dt.day() <= 5) {
      let dayAfterTomorrow = moment().add(2, 'days');
      dayAfterTomorrow.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      let thisSunday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).day(5).add(2, 'days');

      this.eventsRestOfWeek = this.systemData?.calendarEvents.filter(c => (moment(c.calendarStartDt).isAfter(dayAfterTomorrow) && moment(c.calendarStartDt).isBefore(thisSunday)) ||
        (moment(c.calendarEndDt).isAfter(dayAfterTomorrow) && moment(c.calendarEndDt).isBefore(thisSunday)));
      this.eventsRestOfWeek = this.eventsRestOfWeek?.sort((a, b) => {
        if (moment(a.calendarStartDt).isAfter(moment(b.calendarStartDt))) {
          return 1;
        } else if (moment(a.calendarStartDt).isBefore(moment(b.calendarStartDt))) {
          return -1;
        } else {
          return 0;
        }
      });
    }

    let thisSunday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).day(6).add(1, 'days');
    let nextSunday = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).day(6).add(7, 'days');
    this.eventsNextWeek = this.systemData?.calendarEvents.filter(c => (moment(c.calendarStartDt).isAfter(thisSunday) && moment(c.calendarStartDt).isBefore(nextSunday.add(1, 'seconds'))) ||
      (moment(c.calendarEndDt).isAfter(thisSunday) && moment(c.calendarEndDt).isBefore(nextSunday.add(1, 'seconds'))));
    this.eventsNextWeek = this.eventsNextWeek?.sort((a, b) => {
      if (moment(a.calendarStartDt).isAfter(moment(b.calendarStartDt))) {
        return 1;
      } else if (moment(a.calendarStartDt).isBefore(moment(b.calendarStartDt))) {
        return -1;
      } else {
        return 0;
      }
    });

    this.eventsLater = this.systemData?.calendarEvents.filter(c => (moment(c.calendarStartDt).isAfter(nextSunday)));
    this.eventsLater = this.eventsLater?.sort((a, b) => {
      if (moment(a.calendarStartDt).isAfter(moment(b.calendarStartDt))) {
        return 1;
      } else if (moment(a.calendarStartDt).isBefore(moment(b.calendarStartDt))) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  getTooltipClass(cEvent: CalendarEvent): string {
    let tClass = 'event-tooltip event-tooltip-';
    tClass += cEvent.calendarKey.toString();
    return tClass;
  }

  getEventClass(cEvent: CalendarEvent): string {
    let tClass = 'events-event events-event-';
    tClass += cEvent.calendarKey.toString();
    return tClass;
  }

  getPersonalEventBody(body: string) {
    if (body.length > 500) {
      return body.substring(0, 500) + '...';
    } else {
      return body;
    }
  }

  getEventTooltip(cEvent: CalendarEvent): string {
    let tooltip = '';
    let start = moment(cEvent.calendarStartDt).format('M/D');
    let end = moment(cEvent.calendarEndDt).format('M/D');
    if (start === end) {
      tooltip += moment(cEvent.calendarEndDt).format('ddd MMM D');
    } else {
      tooltip += start + ' - ' + end;
    }
    return tooltip;
  }

  selEvent(cEvent: CalendarEvent) {
    this.selectedEvent = cEvent;
  }

  selPersonalEvent(event: any) {
    this.selectedPersonalEvent = event;
  }

  getEventIcon(cEvent: CalendarEvent): any {
    let fa = faCalendar;
    switch (cEvent.calendarKey) {
      case 1:
      case 2:
      case 6:
      case 7:
        fa = faPresentationScreen;
        break;
      case 3:
        fa = faCalendar;
        break;
      case 4:
        fa = faBirthdayCake;
        break;
      case 8:
        fa = faClockDesk;
        break;
      case 9:
        fa = faSquareDollar;
        break;
      default:
        break;
    }
    return fa;
  }

  eventClick(cEvent: CalendarEvent) {
    switch (cEvent.calendarKey) {
      case 1:
      case 2:
      case 3:
      case 6:
      case 7:
        window.open(cEvent.url);
        break;
      case 4:
      case 8:
      case 9:
        this.router.navigate([cEvent.url]);
        break;
      default:
        break;
    }
  }

  gotoMarchMadness() {
    this.router.navigate(['marchmadness']);
  }

  setupSections(): void {
    if (this.me.department === 'Investments' || this.me.jobTitle === 'CIO') {
      this.dashboardSections = ['Investments', 'Corporate', 'Asset Management'];
    } else if (this.me.department === 'Asset Management' || this.me.jobTitle === 'Operations' || this.me.jobTitle === 'COO') {
      this.dashboardSections = ['Asset Management', 'Corporate', 'Investments'];
    } else if (this.me.department === 'Asset Management' || this.me.jobTitle === 'Operations' || this.me.jobTitle === 'COO') {
      this.dashboardSections = ['Asset Management', 'Corporate', 'Investments'];
    } else if (this.me.department === 'Finance' || this.me.jobTitle === 'CFO') {
      this.dashboardSections = ['Corporate', 'Asset Management', 'Investments'];
    }
  }

  ngOnInit() {
    this.loggerService.logPageVisit('dashboard', document.location.href);
    this.titleService.setTitle('CUB - Dashboard');

    // show old cub?
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let oldCUB = params['showOldCUB'];
      if (oldCUB !== null && oldCUB === 'false') {
        this.showOldCUB = false;
      }
    });

    this.menuService.showPoint$.subscribe(v => {
      this.showPoint = v;
    });

    this.portalService.systemData$.subscribe(d => {
      this.systemData = d;
      this.filterCalendarObjects();

      this.appraisalChartData = [];
      this.appraisalChartData.push({ data: this.systemData.portfolioStats.filter(p => p.navValue > 0).map(p => p.navValue / 1000000000), label: 'NAV' });
      this.appraisalChartLabels = this.systemData.portfolioStats.filter(p => p.navValue > 0).map(p => p.year.toString());
      let navData = this.systemData.portfolioStats.filter(p => p.navValue > 0);
      if (navData.length > 0) {
        this.appraisalVal1 = this.dp.transform(navData[navData.length - 1].navValue / 1000000000, '1.1-1');
      }

      this.sfVsAcresChartData = [];
      this.sfVsAcresChartData.push({ data: this.systemData.portfolioStats.filter(p => p.sqFt > 0).map(p => p.sqFt / this.sfMultiplier), label: 'SF' });
      this.sfVsAcresChartData.push({ data: this.systemData.portfolioStats.filter(p => p.acres > 0).map(p => p.acres), label: 'Acres' });
      this.sfVsAcresChartLabels = this.systemData.portfolioStats.filter(p => p.sqFt > 0).map(p => p.year.toString());
      let sfData = this.systemData.portfolioStats.filter(p => p.sqFt > 0);
      if (sfData.length > 0) {
        this.sfAcreVal1 = this.dp.transform(sfData[sfData.length - 1].sqFt / 1000000, '1.1-1');
      }
      let acreData = this.systemData.portfolioStats.filter(p => p.acres > 0);
      if (acreData.length > 0) {
        this.sfAcreVal2 = this.dp.transform(acreData[acreData.length - 1].acres, '1.0-0');
      }

      this.revenueChartData = [];
      this.revenueChartData = this.systemData.sysSummaryData.filter(d2 => d2.typeName === 'Revenue').map(p => p.totalSF);
      this.revenueChartLabels = this.systemData.sysSummaryData.filter(d2 => d2.typeName === 'Revenue').map(p => p.name);

      this.goalData = this.systemData.gauges;
    });

    this.portalService.quarterlySystemData$.subscribe(d => {
      this.quarterlySystemData = d.sort((a, b) => {
        if (a.year > b.year) {
          return 1;
        } else if (a.year < b.year) {
          return -1;
        } else {
          if (a.quarter > b.quarter) {
            return 1;
          } else if (a.quarter < b.quarter) {
            return -1;
          } else {
            return 0;
          }
        }
      });
      this.selectQuarter(this.quarterlySystemData[d.length - 1], d.length - 1);

      this.propChartData = [{ data: this.quarterlySystemData.map(q => q.propertyCount), label: 'Properties' }];
      this.propChartLabels = this.quarterlySystemData.map(p => p.year.toString() + ' Q' + p.quarter.toString());

      this.leaseChartData = [{ data: this.quarterlySystemData.map(p => p.tenantCount), label: 'Leases' }];
      this.leaseChartLabels = this.quarterlySystemData.map(p => p.year.toString() + ' Q' + p.quarter.toString());

      let sharePriceArray = this.quarterlySystemData.map(p => p.sharePrice);
      this.sharePriceLatest = '$' + sharePriceArray[sharePriceArray.length - 1].toString();
      this.sharePriceChartData = [{ data: sharePriceArray, label: 'Share Price' }];
      this.sharePriceChartLabels = this.quarterlySystemData.map(p => p.year.toString() + ' Q' + p.quarter.toString());

      let leverageArray = this.quarterlySystemData.map(p => p.leverage * 100);
      this.leverageLatest = this.dp.transform(leverageArray[leverageArray.length - 1], '1.1-1') + '%';
      this.leverageChartData = [{ data: leverageArray, label: 'Leverage' }];
      this.leverageChartLabels = this.quarterlySystemData.map(p => p.year.toString() + ' Q' + p.quarter.toString());

      this.acreChartData = [{ data: this.quarterlySystemData.map(q => q.acres), label: 'Acres' }];
      this.acreChartLabels = this.quarterlySystemData.map(p => p.year.toString() + ' Q' + p.quarter.toString());
      this.acreChartAmount = this.dp.transform(this.acreChartData[0].data[this.acreChartLabels.length - 1], '1.0-0');

      let occList = this.quarterlySystemData;
      this.occChartData = [{ data: occList.map(p => p.occupancy), label: 'Occupancy' }];
      this.occChartLabels = this.quarterlySystemData.map(p => p.year.toString() + ' Q' + p.quarter.toString());
      this.currentOcc = occList[occList.length - 1].occupancy;

      let sfList = this.quarterlySystemData;
      this.sfChartData = [{ data: sfList.map(p => p.squareFeet), label: 'Sq Ft' }];
      this.sfChartLabels = this.quarterlySystemData.map(p => p.year.toString() + ' Q' + p.quarter.toString());
      this.currentSf = sfList[sfList.length - 1].squareFeet;

      let navList = this.quarterlySystemData;
      // this.navChartData = [{ data: [null, null, 2.43], label: 'NAV' }, { data: navList.map(p => p.nav), label: 'NAV' }];
      this.navChartData = [{ data: navList.map(p => p.nav), label: 'NAV' }];
      this.navChartLabels = this.quarterlySystemData.map(p => p.year.toString() + ' Q' + p.quarter.toString());
      this.navAmount = '$' + this.dp.transform(navList[navList.length - 1].nav, '1.1-1');
    });

    this.calendarOptions = {
      nowIndicator: false,
      height: 217,
      dayHeaderContent: this.getHeader,
      initialView: "dayGridWeek",
      plugins: this.calendarPlugins,
      headerToolbar: {
        left: '',
        center: 'title',
        right: ''
      },
      weekends: this.calendarWeekends,
      events: [],
      eventOrder: function (a: any, b: any) {
        var result = 0;
        if (a.extendedProps.isBirthday) result = 1;
        else if (a.extendedProps.isAnniversary) result = 1;
        else if (a.extendedProps.isInvClose) result = -1; //event b should be listed first
        else {
          if (a.name > b.name) {
            return 1;
          }
          else if (a.name < b.name) {
            return -1;
          }
        }
        return result;
      },
      eventContent: this.eventRender.bind(this)
    };
  }

  getHeader = function name(arg: any) {
    return arg.date.getDate();
  };

  eventRender(evt: any) {
    if (evt.event.extendedProps.isBirthday === true) {
      return {
        html: `<img
                  style="width: 12px; margin-right: 8px; margin-left: 5px; vertical-align: text-top; margin-top: 2px;"
                  alt="birthday cake icon"
                  src="assets/img/birthdayCakeRed.png"> ${evt.event.title}`,
      }
    } else if (evt.event.extendedProps.isAnniversary === true) {
      return {
        html: `<img
                  style="width: 12px; margin-right: 8px; margin-left: 5px; vertical-align: text-top; margin-top: 2px;"
                  alt="anniversary icon"
                  src="assets/img/clock-desk-gold.png"> ${evt.event.title}`,
      };
    } else if (evt.event.extendedProps.isInvClose === true) {
      return {
        html: `<img
                  style="width: 12px; margin-right: 8px; margin-left: 5px; vertical-align: text-top; margin-top: 2px;"
                  alt="inv closing icon"
                  src="assets/img/square-dollar-light.png"> ${evt.event.title}`,
      };
    } else {
      return evt.event.title;
    }
  }

  selectQuarter(q: QuarterlyMetric, i: number) {
    this.selQuarterIndex = i;
    this.selQuarterName = q.year + ' Q' + q.quarter;
  }

  getAmount(field: string, pre: string, post: string, format: string): string {
    let amt = 0;
    let amtString = '';

    if (this.quarterlySystemData.length > 0) {
      amt += parseFloat(this.quarterlySystemData[this.quarterlySystemData.length - this.selQuarterIndex - 1][field]);
      if (post === '%') {
        amt = amt * 100;
      }
    }
    if (format.length > 0) {
      amtString = this.dp.transform(amt, format);
    }
    else {
      amtString = amt.toString();
    }
    return pre + amtString + post;
  }

  toggleNewPoint(): void {
    this.showNewPoint = !this.showNewPoint;
  }

  getTeamImage(team: SportsTeam): string {
    let teamUrl: string;
    if (team !== undefined) {
      if (team.url === 'http://www.chicagobears.com/') {
        teamUrl = 'bears.png';
      } else if (team.url === 'http://www.bulls.com') {
        teamUrl = 'bulls.png';
      } else if (team.url === 'http://blackhawks.nhl.com/') {
        teamUrl = 'blackhawks.png';
      } else if (team.url === 'http://www.cubs.com') {
        teamUrl = 'cubs-logo-60x.png';
      } else if (team.url === 'http://www.whitesox.com/') {
        teamUrl = 'sox.png';
      } else if (team.url === 'http://www.ladodgers.com') {
        teamUrl = 'dodgers.png';
      }
    }
    return './assets/img/teams/' + teamUrl;
  }

  getLunchToday(): string {
    let lunch = '';
    let lunchToday = this.systemData.lunchMenuDays.filter(l => l.weekDayName === moment().isoWeekday());
    if (lunchToday.length > 0) {
      lunch = lunchToday[0].entree;
    }
    return lunch;
  }

  goToUrl(url: string, localRoute: boolean): void {
    let newUrl = url;
    if (document.location.href.startsWith('https')) {
      newUrl = url.replace('http://cub/', 'https://cub.centerpoint.com/');
      newUrl = newUrl.replace('http://cubapi/', 'https://cub.centerpoint.com/');
    }
    if (localRoute) {
      this.router.navigate([newUrl]);
    } else {
      window.open(newUrl);
    }
  }

  getBrackets() {
    this.loadingBrackets = true;
    this.bracketService.bracketList$.subscribe(bs => {
      this.allBrackets = bs;
      this.loadingBrackets = false;
      this.getBracketsForYear(this.bracketYear);
    });
  }

  getBracketsForYear(year: string) {
    this.brackets = this.allBrackets.filter(br => +br.year === +this.bracketYear && !br.master);
    this.masterBracket = this.allBrackets.filter(br => +br.year === +this.bracketYear && br.master)[0];
    this.brackets.forEach(br => {
      br.points = Bracket.getPoints(br, this.masterBracket);
    });
    this.brackets = this.brackets.sort((a, b) => a.points < b.points ? 1 : a.points > b.points ? -1 : 0);
  }

  isMyBracket(b: Bracket): boolean {
    let mine = false;
    if (this.me && b) {
      mine = b.userName === (this.me?.accountName?.replace('CENTERPOINT-PRO\\', '') + '@centerpoint.com');
    }
    return mine;
  }

  goToBracket(b: Bracket) {
    this.router.navigate(['/marchmadness/brackets', b.id]);
  }

  getMadnessRows(): string {
    let str = 'repeat(';
    let rows = this.brackets.length / 3;
    if (this.brackets.length % 3 > 0)
      rows = Math.floor(rows) + 1;
    str += rows.toString();
    str += ', 30px)';
    return str;
  }

  getMadnessDivHeight(): number {
    let height = this.brackets.length / 3;
    if (this.brackets.length % 3 > 0)
      height = Math.floor(height) + 1;

    return (height * 30) + 25;
  }

  newBracket() {
    if (this.me.firstName.length > 0 && this.me.workEmail.length > 0) {
      this.router.navigate(['/marchmadness/brackets', 0]);
    }
    else {
      alert('There is an error with your local account preventing you from creating a bracket. Please contact MIS');
      this.loggerService.logNote('Can not create bracket for user: firstname: ' + this.me.firstName + ', workemail: ' + this.me.workEmail + ', accountname' + this.me.accountName, 'dashboard', '', false);
    }
  }
}
