import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Bracket, BracketObject } from './bracket/bracket';
import { ReplaySubject, Observable, map, tap } from 'rxjs';
import * as moment from 'moment';
import { MarchMadnessConstants } from './contants';
import { User } from 'src/app/user/user';

@Injectable()
export class BracketService {

  user: User;
  private rootUrl = environment.cubCloudUrl + 'marchmadness/';
  private httpOptions = {};
  private _bracketList: Bracket[] = [];
  private bracketList = new ReplaySubject<Bracket[]>(1);
  bracketList$ = this.bracketList.asObservable();   // $ is onnv
  private toggleTopBar = new ReplaySubject<boolean>(1);
  toggleTopBar$ = this.toggleTopBar.asObservable();

  constructor(
    private http: HttpClient
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // TODO: This causes a call to update the brackets
    // In dashboard.component.ts we have a flag that calls `getBrackets` only
    // if `isMarchMadnessTime` is true. But having this call in the
    // constructor defeats that optimization
    // - Raju Gandhi 20241215
    // this.refreshBrackets();
    this.user = JSON.parse(localStorage.getItem('user')) as User;
    this.toggleTopBar.next(true);
  }

  setTopBarVisible(mode: boolean) {
    this.toggleTopBar.next(mode);
  }

  getBrackets() {
    console.error('🚀 ~ getBrackets');
    return this.http.get<BracketObject[]>(this.rootUrl + 'brackets', this.httpOptions)
      .pipe(
        tap(() => console.error('🚀 ~ getBrackets')),
        map(brackets => {
          let bracketList = [];
          brackets.forEach(bd => {
            let bracket: Bracket;
            bracket = JSON.parse(bd.bracket) as Bracket;
            bracket.id = bd.id;
            bracketList.push(bracket);
          });
          return bracketList;
        })
      );
  }

  refreshBrackets() {
    console.trace('🚀 ~ refreshBrackets');

    return this.http.get<BracketObject[]>(this.rootUrl + 'brackets', this.httpOptions).subscribe(d => {
      this._bracketList = [];
      d.forEach(bd => {
        let bracket: Bracket;
        bracket = JSON.parse(bd.bracket) as Bracket;
        bracket.id = bd.id;
        this._bracketList.push(bracket);
      });
      this.bracketList.next(this._bracketList);
    });
  }

  createNewBracket(user: User): Bracket {
    let newBracket = new Bracket();
    newBracket = JSON.parse(JSON.stringify(this._bracketList.filter(br => br.year === moment(MarchMadnessConstants.CutOffDt).year() && br.master)))[0];
    newBracket.id = 0;
    newBracket.master = false;
    newBracket.userName = this.user.workEmail;
    newBracket.paid = false;
    newBracket.name = this.user.firstName + '\'s Bracket';
    newBracket.year = moment(MarchMadnessConstants.CutOffDt).year();
    return newBracket;

    //const serverBracket = new BracketObject();
    //serverBracket.id = 0;
    //serverBracket.bracket = JSON.stringify(newBracket);
    //console.log('create new bracket', newBracket, serverBracket);
    //return this.saveBracket(serverBracket);
  }

  saveBracket(bracketObject: BracketObject) {
    return this.http.post<Bracket>(this.rootUrl + 'brackets', JSON.stringify(bracketObject), this.httpOptions);
  }

  deleteBracket(bracket: Bracket) {
    return this.http.delete<Bracket>(this.rootUrl + 'brackets/' + bracket.id, this.httpOptions);
  }
}
