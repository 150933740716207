
import { Address } from 'src/app/shared/address';

export class Investment {
  archived: boolean;
  archivedChanged: boolean;
  key: number;
  name: string;
  location: string;
  purchasePrice: number;
  tac: number;
  totalSF: number;
  occupiedSF: number;
  vacantSF: number;
  landSF: number;
  occupancyPct: number;
  capRate: number;
  straightLine: number;
  irrLevered: number;
  irrUnlevered: number;
  leaseTermInPlace: number;
  rentPerSF: number;
  closingPct: number;
  seller: string;
  tenant: string;
  broker: string;
  cntContacts: number[];
  firm: string;
  closed: boolean;
  pricePerSF: number;
  tacPerSF: number;
  statusKey: number;
  statusDesc: string;
  onTimeline: boolean;
  onReportCalEast: boolean;
  onReportRevenue: boolean;
  onReportITC: boolean;
  onReportRegion: boolean;
  annualNOI: number;
  noiPSF: number;
  baseIRR: number;
  initialYield: number;
  stableYield: number;
  projectDesc: string;
  calpersShare: number;
  comments: string;
  debtAssumed: number;
  debtNew: number;
  debtTotal: number;
  interestRate: number;
  ltv: number;
  ownershipPct: number;
  latitude: number;
  longitude: number;
  regions: string;
  markets: string;
  cities: string;
  states: string;
  directoryPath: string;
  fullDirectoryPath: string;
  costPerMetric: string;
  archivedBy: string;
  properties: InvestmentProperty[];
  priority: number;
  analysisStatusKey: number;
  doors: number;
  pipelineGroupKey: number;
  stableCapRate: number;
  acres: number;
  yearBuilt: number;
  invStrategyKey: number;
  procuringBroker: string;
  brokerFirm: string;
  commissionAmt: number;
  esgMatrixId: number;
  internalRegionalNotes: string;
  coverageRatio: number;
  fellOffLastReport: boolean;

  aaDt: Date;
  icDt: Date;
  ddStartDt: Date;
  ddEndDt: Date;
  dealStartDt: Date;
  boardDt: Date;
  archivedDt: Date;
  createdDt: Date;
  updatedDt: Date;
  contractDt: Date;
  goFirmDt: Date;
  closingDt: Date;
  builtDt: Date;
  itcDt: Date;
  giPreviewDt: Date;
  prelimEsgDt: Date;
  finalEsgDt: Date;
  ddInspectionDt: Date;
  addedToPipelineDt: Date;
  isUnderwritingFinal: boolean;
  isFortress: boolean;
  bidProcessKey: number;
  tenantDetails: string;
  brokerAsk: number;

  // not on server
  activeItem: boolean;
  scrollIndex: number;

  constructor() {
    this.aaDt = null;
    this.properties = [];
    this.cntContacts = [];
    this.ownershipPct = 0.9959;
    this.invStrategyKey = 1;
    this.ltv = .3;
  }
}


export class InvestmentProperty {

  key: number;
  prospectKey: number;
  address: Address;
  id: string;
  desc: string;
  marketKey: number;
  marketDesc: string;
  regionKey: number;
  regionDesc: string;
  greenStreetRatingKey: number;
  greenStreetRatingKeyName: string;
  msaKey: number;
  leasableSF: number;
  directoryPath: string;
  markToDelete: number;
  strategicRelevanceKey: number;
  tac: number;
  purchasePrice: number;
  unitCount: number;
  structureTypeKey: number;
  calpersMarketKey: number;
  closingDt: Date;

  constructor() {
    this.key = 0;
    this.address = new Address();
    this.desc = '';
  }
}

export class InvestmentDate {
  prospectKey: number;
  dateVal: Date;
  dateName: string;
  prospectName: string;

  constructor(key?: number, dateVal?: Date, dateName?: string, prospectName?: string) {
    if (key) {
      this.prospectKey = key;
    }
    if (dateVal) {
      this.dateVal = dateVal;
    }
    if (dateName) {
      this.dateName = dateName;
    }
    if (prospectName) {
      this.prospectName = prospectName;
    }
  }
}
